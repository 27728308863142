import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '../views/LoginPage.vue';
import PointsPage from '../views/PointsPage.vue';
import Cookies from 'js-cookie';

const routes = [
  { 
    path: '/', 
    redirect: '/login' 
  },
  { 
    path: '/login', 
    component: LoginPage ,
    meta: {
      title: 'Java学习分享-登录页'
    }
  },
  { 
    path: '/points', 
    component: PointsPage,
    meta: { 
      requiresAuth: true ,
      title: 'Java学习分享-积分页'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !Cookies.get('token')) {
    next('/login');
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router; 