import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs'; // 导入 qs 库

const instance = axios.create({
  baseURL: 'http://www.lijianbai.top',
  // baseURL: 'http://localhost:8081',
  timeout: 1000,
});

// 添加请求拦截器
instance.interceptors.request.use(config => {
  const token = Cookies.get('token'); // 使用 js-cookie 获取 token
  if (token) {
    config.headers.Authorization = `${token}`; // 设置 Authorization 头
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    // 清除 token 并跳转到登录页
    Cookies.remove('token');
    localStorage.removeItem('username'); // 清除用户名
    window.location.href = '/login'; // 跳转到登录页
  }
  return Promise.reject(error);
});

// 登录请求函数
export const login = (username, password) => {
  return instance.post('/api/user/umUser/login', qs.stringify({
    username,
    password,
  }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded', // 设置请求头
    },
  });
};

// 注册请求函数
export const register = (username, password) => {
  return instance.post('/api/user/umUser/register', qs.stringify({
    username,
    password,
  }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded', // 设置请求头
    },
  });
};

// 忘记密码请求函数
export const forgotPassword = (username, newPassword, code) => {
  return instance.post('/api/user/umUser/recoverPassword', qs.stringify({
    username,
    newPassword,
    code,
  }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded', // 设置请求头
    },
  });
};

// 获取积分的请求函数
export const fetchPoints = () => {
  return instance.get('/api/score/scScore/get'); // 假设这是获取积分的接口
};

// 查询用户请求函数
export const getUserInfo = () => {
  return instance.get('/api/user/umUser/get'); // 假设这是获取用户信息的接口
};

// 其他请求函数... 