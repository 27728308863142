<template>
  <div id="app">
    <router-view />
    <p class="copyright"><a href="https://beian.miit.gov.cn/" target="_blank">赣ICP备2022001112号-1</a></p>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* 全局样式 */
.emoji {
  text-align: center;
  margin-top: 20px; /* 添加顶部间距 */
}

.copyright {
  text-align: center;
  margin-top: 5px; /* 添加顶部间距 */
  font-size: 12px; /* 字体大小 */
}
</style>
