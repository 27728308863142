<template>
    <div class="login-container">
      <div class="form-wrapper">
      <h1>Java学习分享</h1>
        <div v-if="!isRegister && !isForgotPassword">
          <h2>登录</h2>
          <div class="input-group">
            <input v-model="username" type="text" placeholder="用户名" />
          </div>
          <div class="input-group">
            <input v-model="password" type="password" placeholder="密码" />
            <span class="forgot-password" @click="showForgotPassword">忘记密码？</span>
          </div>
          <div class="button-group">
            <button class="login-button" @click="login">登录</button>
            <button class="register-button" @click="showRegister">注册</button>
          </div>
        </div>
  
        <div v-if="isRegister">
          <h2>注册</h2>
          <div class="input-group">
            <input v-model="registerUsername" type="text" placeholder="用户名" />
          </div>
          <div class="input-group">
            <input v-model="registerPassword" type="password" placeholder="密码" />
          </div>
          <div class="button-group">
            <button class="register-confirm-button" @click="register">确认注册</button>
          <button class="back-button green-button" @click="showLogin">返回登录</button>
          </div>
       </div>
  
        <div v-if="isForgotPassword">
          <h2>忘记密码</h2>
          <div class="input-group">
            <input v-model="username" type="text" placeholder="用户名" />
          </div>
          <div class="input-group">
            <input v-model="newPassword" type="password" placeholder="新密码" />
          </div>
          <div class="input-group">
            <input v-model="verificationCode" type="text" placeholder="验证码" />
          </div>
          <div class="button-group">
            <button class="forgot-confirm-button" @click="forgotPassword">确认</button>
            <button class="back-button green-button" @click="showLogin">返回登录</button>
          </div>
         </div>
      </div>
    </div>
  </template>
  
  <script>
  import { login, register, forgotPassword } from '../utils/request.js'; // 导入请求函数
  import Cookies from 'js-cookie'; // 导入 js-cookie
  
  export default {
    data() {
      return {
        username: '',
        password: '',
        newPassword: '', // 新密码
        registerUsername: '',
        registerPassword: '',
        verificationCode: '',
        isRegister: false,
        isForgotPassword: false,
      };
    },
    methods: {
      async login() {
        try {
          const response = await login(this.username, this.password);
          if (response.data.success) {
            // 保存 token 到 cookies
            Cookies.set('token', response.data.data);
            this.$router.push('/points'); // 登录成功后跳转到积分页面
          } else {
            alert(response.data.msg); // 使用浏览器弹窗
          }
        } catch (error) {
          console.error(error);
          alert('登录失败，请重试'); // 使用浏览器弹窗
        }
      },
      async register() {
        try {
          const response = await register(this.registerUsername, this.registerPassword);
          if (response.data.success) {
            alert('注册成功，请登录'); // 使用浏览器弹窗
            this.showLogin(); // 切换回登录界面
          } else {
            alert(response.data.msg); // 使用浏览器弹窗
          }
        } catch (error) {
          console.error(error);
          alert('注册失败，请重试'); // 使用浏览器弹窗
        }
      },
      async forgotPassword() {
        try {
          const response = await forgotPassword(this.username, this.newPassword, this.verificationCode);
          if (response.data.success) {
            alert('密码重置成功，请返回登录'); // 使用浏览器弹窗
            this.showLogin(); // 切换回登录界面
          } else {
            alert(response.data.msg); // 使用浏览器弹窗
          }
        } catch (error) {
          console.error(error);
          alert('重置密码请求失败，请重试'); // 使用浏览器弹窗
        }
      },
      showRegister() {
        this.isRegister = true;
        this.isForgotPassword = false;
      },
      showForgotPassword() {
        this.isForgotPassword = true;
        this.isRegister = false;
      },
      showLogin() {
        this.isRegister = false;
        this.isForgotPassword = false;
      },
    },
  };
  </script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* 使容器充满整个视口 */
  background-color: #f0f0f5; /* 背景颜色 */
}

.form-wrapper {
  background-color: #ffffff; /* 奶白色底色 */
  padding: 40px; /* 内边距 */
  border-radius: 10px; /* 圆角 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  width: 300px; /* 固定宽度 */
  text-align: center; /* 文本居中 */
}

h1 {
  font-size: 2.5rem; /* 标题大小 */
  margin-bottom: 20px; /* 标题下边距 */
  color: #333; /* 标题颜色 */
}

.input-group {
  position: relative;
  width: 100%; /* 输入框宽度 */
  margin-bottom: 15px; /* 输入框下边距 */
}

.input-group i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa; /* 图标颜色 */
}

input {
  width: 97%; /* 输入框宽度 */
  padding: 10px 0px 10px 10px; /* 输入框内边距 */
  border: 1px solid #ccc; /* 边框 */
  border-radius: 10px; /* 圆角 */
  font-size: 1rem; /* 字体大小 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  transition: border-color 0.3s, box-shadow 0.3s; /* 边框和阴影颜色过渡效果 */
}

input:focus {
  border-color: #007bff; /* 聚焦时边框颜色 */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* 聚焦时阴影效果 */
  outline: none; /* 去掉默认的轮廓 */
}

.forgot-password {
  display: block;
  margin-top: 5px; /* 上边距 */
  color: #007bff; /* 颜色 */
  cursor: pointer; /* 鼠标指针样式 */
  text-align: right; /* 右对齐 */
  text-decoration: none; /* 去掉下划线 */
}

.forgot-password:hover {
  color: #0056b3; /* 悬停时颜色 */
}

.button-group {
  display: flex;
  flex-direction: column; /* 垂直排列按钮 */
  gap: 10px; /* 按钮之间的间距 */
  width: 100%;
}

button {
  padding: 10px 20px; /* 按钮内边距 */
  font-size: 1rem; /* 按钮字体大小 */
  color: #fff; /* 按钮字体颜色 */
  border: none; /* 去掉边框 */
  border-radius: 10px; /* 圆角 */
  cursor: pointer; /* 鼠标指针样式 */
  transition: background-color 0.3s, transform 0.2s; /* 背景颜色和缩放过渡效果 */
}

.login-button {
  background-color: #007bff; /* 登录按钮背景颜色 */
}

.login-button:hover {
  background-color: #0056b3; /* 登录按钮悬停时的背景颜色 */
  transform: scale(1.05); /* 悬停时放大效果 */
}

.register-button {
  background-color: #28a745; /* 注册按钮背景颜色 */
}

.register-button:hover {
  background-color: #218838; /* 注册按钮悬停时的背景颜色 */
  transform: scale(1.05); /* 悬停时放大效果 */
}

.register-confirm-button,
.forgot-confirm-button {
  background-color: #007bff; /* 确认按钮背景颜色 */
  
}

.register-confirm-button:hover,
.forgot-confirm-button:hover {
  background-color: #0056b3; /* 确认按钮悬停时的背景颜色 */
  transform: scale(1.05); /* 悬停时放大效果 */
}

.back-button {
  background-color: #28a745; /* 返回按钮背景颜色 */
}

.back-button:hover {
  background-color: #218838; /* 返回按钮悬停时的背景颜色 */
  transform: scale(1.05); /* 悬停时放大效果 */
}
</style> 